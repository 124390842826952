import "./FooterStyles.css";

import React from "react";
import {
  FaHome,
  FaPhone,
  FaMailBulk,
  FaLinkedin,
  FaGithub,
} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="left">
          <h3>Me contacter</h3>
          <div className="location">
            <h4>
              <FaHome size={20} style={{ color: "fff", marginRight: "2rem" }} />
              Paris,France
            </h4>
          </div>

          <div className="email">
            <h4>
              <FaMailBulk
                size={20}
                style={{ color: "fff", marginRight: "2rem" }}
              />
              nverdavtyan1994@gmail.com
            </h4>
          </div>
        </div>

        <div className="right">
          <h3>Contact</h3>
          <p>
            Développeur Full Stack à Paris. Création ou refonte de site
            internet, intégration, référencement, etc.
          </p>

          <div className="social">
            <a
              href="https://www.linkedin.com/in/nverdavtyan/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <FaLinkedin
                size={30}
                style={{ color: "fff", marginRight: "1rem" }}
              />
            </a>
            <a
              href="https://github.com/nverdavtyan"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <FaGithub
                size={30}
                style={{ color: "fff", marginRight: "1rem" }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
