import { Outlet, Link } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../../config/firebase';
function index() {
  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        console.log('User signed out');
      })
      .catch((error) => {
        // An error happened.
        console.error('Error signing out', error);
      });
  };

  return (
    <div className="p-8">
      <h1 className="text-4xl mb-6">Admin</h1>
      <nav className="mb-10">
        <Link to="works" className="text-blue-500 hover:text-blue-800 mr-4">
          Works
        </Link>
        <Link to="add" className="text-blue-500 hover:text-blue-800">
          Add
        </Link>
        <button
          onClick={handleSignOut}
          className="text-blue-500 hover:text-blue-800 border border-blue-500 hover:border-blue-800 py-2 px-4 rounded"
        >
          Sign Out
        </button>
      </nav>
      <Outlet />
    </div>
  );
}

export default index;
