import React from 'react'
import './HelloImg2Styles.css'

const HelloImg2 = (props) => {
  return (
    <div className="hello-img">

        <div className="heading">
            <div className="text-container">
<h1>{props.heading}</h1>
<p>{props.text}</p>
</div>
        </div>
    </div>
  )
}

export default HelloImg2