import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar';
import HelloImg2 from '../components/HelloImg2';
import Work from '../components/Work'
import {motion} from 'framer-motion'
const Project = () => {
    return (
      <motion.div 
    initial={{opacity: 0, y: 8 }}
    animate={{
      opacity: 1,
      y: 0,
      transition: {
        duration: 3,
        ease: [0.61, 1, 0.88, 1],
      }
    }}
     >
        <Navbar/>
        <HelloImg2 heading="Portfolio" text="MES PROJETS RÉCENTS"/>
        <Work/>
        <Footer />
        </motion.div >
  )
}

export default Project