import js from "../assets/logos/javascript-logo.png";
import ts from "../assets/logos/typescript.png";
import react from "../assets/logos/react-logo.png";
import reactnative from "../assets/logos/react-native.png";
import nestjs from "../assets/logos/nestjs.png";
import angular from "../assets/logos/angular.png";
import docker from "../assets/logos/docker.png";
import postgre from "../assets/logos/postgre.png";
import next from "../assets/logos/nextjs.png";
const skillsData = [
  {
    id: 1,
    name: "JavaSript",
    img: js,
  },
  {
    id: 2,
    name: "TypeScript",
    img: ts,
  },

  {
    id: 3,
    name: "React",
    img: react,
  },
  // {
  //   id: 4,
  //   name: "NextJS",
  //   img: next,
  // },
  {
    id: 5,
    name: "React Native",
    img: reactnative,
  },

  {
    id: 6,
    name: "NestJs",
    img: nestjs,
  },

  {
    id: 7,
    name: "Angular",
    img: angular,
  },
  {
    id: 8,
    name: "Docker",
    img: docker,
  },
  {
    id: 9,
    name: "PostgreSQL",
    img: postgre,
  },
];

export default skillsData;
