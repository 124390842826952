import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar';
import HelloImg2 from '../components/HelloImg2';
import {motion} from 'framer-motion'
import CardDetails from '../components/CardDetails';

const ProjectDetails = () => {


    return (
        <motion.div 
        initial={{width:0}}
        animate={{width:"100%" }}
        exit={{x:window.innerWidth, transition: {duration:0.4}}}
         >
          <Navbar/>
          <HelloImg2 heading="Portfolio" text="Details de projet"/>
    <CardDetails/>
          <Footer />
          </motion.div >
    )
}

export default ProjectDetails