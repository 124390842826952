import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar';
import HelloImg2 from '../components/HelloImg2';
import Form from '../components/Form';
import {motion} from 'framer-motion'
const Contact = () => {
  return (
    <motion.div 
    initial={{width:0}}
    animate={{width:"100%" }}
    exit={{x:window.innerWidth, transition: {duration:0.4}}}
     >
    <Navbar/>
    <HelloImg2 heading="Contactez moi !"
     text=""/>

    <Form/>
    <Footer />
    </motion.div >
  )
}

export default Contact