import "./AboutStyles.css";

import React from "react";
import { Link } from "react-router-dom";
import img1 from "../assets/react1.jpg";
import img2 from "../assets/react2.webp";
import cv from "../assets/Nver_Davtyan.pdf";
const About = () => {
  return (
    <div className="about">
      <div className="left">
        <h1>Qui suis-je ?</h1>
        <p>
          Bonjour, je m'appelle Nver, Développeur Full Stack spécialisé dans la
          création d'applications web performantes et évolutives. Ma technologie
          de prédilection comprend NestJS pour la construction de back-ends
          robustes, ReactJS pour des front-ends dynamiques et réactifs, et
          PostgreSQL pour une gestion de base de données efficace et fiable. Je
          possède également une expertise approfondie en React Native, ce qui me
          permet de développer des applications mobiles multiplateformes de
          haute qualité. Passionné par l'innovation et toujours à la recherche
          de nouvelles technologies, je suis dédié à fournir des solutions
          optimales et adaptées aux besoins spécifiques de chaque projet.
        </p>
        <div>
          <a href={cv} target="_blank" rel="noreferrer" className="btn">
            Voir le CV
          </a>
        </div>
      </div>

      <div className="right">
        <div className="img-container">
          <div className="img-stack top">
            <img src={img1} alt="true" className="img" />
          </div>

          <div className="img-stack bottom">
            <img src={img2} alt="true" className="img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
