import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar';
import HelloImg2 from '../components/HelloImg2';
import AboutContent from '../components/About';
import {motion} from 'framer-motion'
const About = () => {
    return (
        <motion.div 
        initial={{width:0}}
        animate={{width:"100%" }}
        exit={{x:window.innerWidth, transition: {duration:0.4}}}
         >
        
        <Navbar/>
        <HelloImg2 heading="Qui suis-je ?"
         text="Développeur Web Full Stack "/>

        <AboutContent/>
        <Footer />
        </motion.div >
      )
}

export default About