import React from 'react';
import Navbar from '../components/Navbar';
import HelloImg from '../components/HelloImg';
import Footer from '../components/Footer';
import MySkills from '../components/MySkills';
import Works from '../components/Work';
import { motion } from 'framer-motion';
const Home = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 8 }}
      animate={{
        opacity: 1,
        y: 0,
        transition: {
          duration: 3,
          ease: [0.61, 1, 0.88, 1],
        },
      }}
    >
      <Navbar />
      <HelloImg />
      <MySkills />

      <Footer />
    </motion.div>
  );
};

export default Home;
