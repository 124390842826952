import './NavbarStyles.css';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  FaBars,
  FaTimes,
  FaHome,
  FaBookOpen,
  FaRegAddressCard,
} from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';
const Navbar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const [color, setColor] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 100) {
      setColor(true);
    } else {
      setColor(false);
    }
  };
  window.addEventListener('scroll', changeColor);
  return (
    <div className={color ? 'header header-bg' : 'header'}>
      <Link to="/">
        <h1 className="logo">ND</h1>
      </Link>
      <ul className={click ? 'nav-menu active' : 'nav-menu'}>
        <li className="nav-link">
          <Link to="/">ACCUEIL</Link>
        </li>
        <li>
          <Link to="/portfolio">PORTFOLIO </Link>
        </li>
        <li>
          <Link to="/about">À PROPOS </Link>
        </li>
        <li>
          <Link to="/contact">CONTACT </Link>
        </li>
      </ul>

      <div className="hamburger" onClick={handleClick}>
        {click ? (
          <FaTimes size={20} style={{ color: '#fff' }} />
        ) : (
          <FaBars size={20} style={{ color: '#fff' }} />
        )}
      </div>
    </div>
  );
};

export default Navbar;
