import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { db, storage } from '../../config/firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, addDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProjectForm = () => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const [tags, setTags] = useState([]);

  const onSubmit = async (data) => {
    setLoading(true);
    const storageRef = ref(storage, 'images/' + data.img[0].name);
    const uploadTask = uploadBytesResumable(storageRef, data.img[0]);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        console.log(error);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        console.log('File available at', downloadURL);
        const date = new Date();
        const dateString = date.toLocaleDateString();
        try {
          await addDoc(collection(db, 'projects'), {
            ...data,
            img: downloadURL,
            tags: tags,
          });
          toast.success('Document successfully written!');
          setLoading(false);
        } catch (error) {
          console.error('Error writing document: ', error);
          toast.error('Error writing document: ' + error.message);
          setLoading(false);
        }
      }
    );
  };

  return (
    <div className="max-w-md mx-auto backdrop-blur-3xl rounded-xl shadow-md overflow-hidden md:max-w-2xl m-4 text-white">
      <form onSubmit={handleSubmit(onSubmit)} className="p-8">
        <input
          {...register('name', { required: true })}
          placeholder="Name"
          className="block border border-grey-light w-full p-3 rounded mb-4"
        />
        {errors.name && (
          <p className="text-red-500 text-xs italic">This field is required</p>
        )}

        <input
          {...register('source', { required: true })}
          placeholder="Source"
          className="block border border-grey-light w-full p-3 rounded mb-4"
        />
        {errors.source && (
          <p className="text-red-500 text-xs italic">This field is required</p>
        )}

        <input
          {...register('demo', { required: false })}
          placeholder="Demo"
          className="block border border-grey-light w-full p-3 rounded mb-4"
        />

        <textarea
          {...register('info', { required: true })}
          placeholder="Info"
          className="block border border-grey-light w-full p-3 rounded mb-4"
        ></textarea>
        {errors.info && (
          <p className="text-red-500 text-xs italic">This field is required</p>
        )}

        <input
          {...register('duration', { required: true })}
          placeholder="Duration"
          className="block border border-grey-light w-full p-3 rounded mb-4"
        />
        {errors.duration && (
          <p className="text-red-500 text-xs italic">This field is required</p>
        )}
        <input
          {...register('year', { required: true })}
          type="number"
          min="2021"
          placeholder="Year"
          className="block border border-grey-light w-full p-3 rounded mb-4"
        />
        {errors.year && (
          <p className="text-red-500 text-xs italic">This field is required</p>
        )}

        <input
          type="file"
          {...register('img', { required: true })}
          className="block border border-grey-light w-full p-3 rounded mb-4"
        />
        {errors.img && (
          <p className="text-red-500 text-xs italic">This field is required</p>
        )}

        <InputTags
          register={register}
          setValue={setValue}
          watch={watch}
          tags={tags}
          setTags={setTags}
        />
        <button
          type="submit"
          className="w-full text-center py-3 rounded bg-blue-500 text-white hover:bg-blue-dark focus:outline-none my-1"
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Add Project'}
        </button>
      </form>
    </div>
  );
};

export default ProjectForm;

const InputTags = ({ register, setValue, watch, tags, setTags }) => {
  const watchTags = watch('tags', '');

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && watchTags.length > 0) {
      event.preventDefault();
      setTags([...tags, watchTags]);
      setValue('tags', '');
    }
  };

  const handleDelete = (tagToDelete) => {
    setTags(tags.filter((tag) => tag !== tagToDelete));
  };

  return (
    <div>
      <input
        {...register('tags')}
        onKeyDown={handleKeyDown}
        placeholder="Enter techno and press 'Enter'"
        className="outline-none p-2 rounded w-full"
      />
      <div className="flex flex-wrap mt-2">
        {tags.map((tag, index) => (
          <span
            key={index}
            className="bg-gray-200 rounded-full px-2 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
          >
            {tag}
            <span
              onClick={() => handleDelete(tag)}
              className="ml-2 cursor-pointer"
            >
              x
            </span>
          </span>
        ))}
      </div>
    </div>
  );
};
