import React, { useContext ,useState,useEffect} from 'react';
import { Navigate } from 'react-router-dom';
import { auth } from '../config/firebase';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user)
    });

    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export function PrivateRoute({ element: Element, ...props }) {
  const { currentUser } = useContext(AuthContext);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return <Element {...props} />;
}

export function PublicRoute({ element: Element, ...props }) {
  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Navigate to="/admin" />;
  }

  return <Element {...props} />;
}
