import React, { useEffect, useState } from 'react';
import { collection, onSnapshot, query } from 'firebase/firestore';
import { db } from '../config/firebase';
import Card from './Card';
import { motion, AnimatePresence } from 'framer-motion';

const Work = () => {
  const [portfolioData, setPortfolioData] = useState([]);
  const [activeFilter, setActiveFilter] = useState('Tout');
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    const q = query(collection(db, 'projects'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      let projects = [];
      let techs = new Set();
      snapshot.forEach((doc) => {
        const data = doc.data();
        projects.push({ id: doc.id, ...data });
        data.tags.forEach((language) => techs.add(language));
      });
      setPortfolioData(projects);
      setFilters(['Tout', ...techs]);
    });

    return () => unsubscribe();
  }, []);

  const filteredData =
    activeFilter === 'Tout'
      ? portfolioData
      : portfolioData.filter((project) => project.tags.includes(activeFilter));

  return (
    <div className="work-container">
      <h1 className="project-heading">Projets</h1>
      <div className="flex flex-wrap items-center justify-center space-x-4 mb-6">
        {filters.map((filter) => (
          <button
            key={filter.id}
            className={`flex items-center justify-center px-6 py-2 m-1 text-sm rounded-full border-none focus:outline-none text-white transition-all duration-400 ease-out cursor-pointer ${
              filter === activeFilter
                ? 'bg-indigo-600 hover:bg-indigo-700'
                : 'bg-white text-indigo-600 hover:bg-indigo-50 border border-indigo-600'
            }`}
            onClick={() => setActiveFilter(filter)}
          >
            {filter}
          </button>
        ))}
      </div>

      <div className="project-container">
        <AnimatePresence>
          {filteredData.map((val, ind) => (
            <motion.div
              key={val.id}
              layout // Add layout here
            >
              <Card
                id={val.id}
                img={val.img}
                name={val.name}
                info={val.info}
                source={val.source}
                demo={val.demo}
                tags={val.tags}
              />
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Work;
