import "./HelloImgStyles.css";

import React from "react";
import IntroImg from "../assets/intro-bg.jpg";
import { Link } from "react-router-dom";
import { Typewriter, useTypewriter, Cursor } from "react-simple-typewriter";
const HelloImg = () => {
  const { text } = useTypewriter({
    words: ["Bonjour, je m'appelle Nver", "Développeur Full Stack."],
    loop: false,
    cursor: true,
    cursorStyle: "_",
    onLoopDone: () => console.log(`loop completed after 3 runs.`),
  });

  return (
    <div className="hello">
      {/* <div className="mask">
            <img className="intro-img" src={IntroImg} alt="IntroImg" />
        </div> */}

      <div className="content">
        <h1>Bienvenue !</h1>
        <p>
          <Typewriter
            words={[
              "Bonjour, je m'appelle Nver",
              "Développeur Web Full Stack.",
            ]}
            loop={false}
            cursor
            cursorStyle="_"
            typeSpeed={70}
            deleteSpeed={50}
            delaySpeed={1000}
          />
        </p>

        {/* 
            <div className="btn-group">
                <Link to="/portfolio"
                className="btn"
                >Portfolio</Link>
                 <a href={cv} target="_blank" rel="noreferrer"
                className="btn  btn-light"
                >Telecharge CV</a>

            </div> */}
      </div>
    </div>
  );
};

export default HelloImg;
