import React from 'react'
import { NavLink } from 'react-router-dom'
import './CardStyles.css'

const Card = (props) => {
    return (
        <NavLink to={`/portfolio/${props.id}`} className="project-card">
            <img src={props.img} alt={props.name} />

            <div className="pro-details">
                <h2 className="project-title">{props.name}</h2>
            </div>
        </NavLink>
    )
}

export default Card
