import React, { useEffect, useState } from 'react';
import './CardDetailsStyles.css';
import { useParams } from 'react-router-dom';
import { db } from '../config/firebase';
import { doc, getDoc } from 'firebase/firestore';

const CardDetails = () => {
  const { id } = useParams();
  const [card, setCard] = useState(null);

  useEffect(() => {
    const fetchCard = async () => {
      const cardDoc = await getDoc(doc(db, 'projects', id));
      if (cardDoc.exists()) {
        setCard({ id: cardDoc.id, ...cardDoc.data() });
      } else {
        console.log('No such document!');
      }
    };

    fetchCard();
  }, [id]);

  return (
    <div className="card-container">
      {card && (
        <div className="card">
          <div className="image">
            <img src={card.img} alt={card.name} />
          </div>
          <div className="details">
            <h1>{card.name}</h1>
            <h2>Description et Informations sur le projet {card.name}</h2>

            <div className="info">
              <p>{card.info}</p>

              <ul>
                <li>
                  Année de production: <b>{card.year}</b>
                </li>
                <li>
                  Temps de production: <b>{card.duration}</b>
                </li>
                <li>
                  Techno: <b>{card.tags.toString()}</b>
                </li>
              </ul>
              <div className="pro-btns">
                {card.demo && (
                  <a
                    href={card.demo}
                    target="_blank"
                    rel="noreferrer"
                    className="btn"
                  >
                    Demo
                  </a>
                )}

                <a
                  href={card.source}
                  target="_blank"
                  rel="noreferrer"
                  className="btn"
                >
                  Source
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CardDetails;
