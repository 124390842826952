import React, { useEffect, useState } from 'react';
import { db } from '../../config/firebase';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { toast } from 'react-toastify';

const ProjectsList = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      const projectsCollection = collection(db, 'projects');
      const projectSnapshot = await getDocs(projectsCollection);
      setProjects(projectSnapshot.docs.map(doc => ({...doc.data(), id: doc.id})));
    };

    fetchProjects();
  }, []);

  const handleDelete = async (id) => {
    await deleteDoc(doc(db, 'projects', id));
    setProjects(projects.filter(project => project.id !== id));
    toast('Project deleted!', { type: 'success' });
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 text-white">
      {projects.map(project => (
        <div key={project.id} className="max-w-sm rounded overflow-hidden shadow-lg m-4">
          <img className="w-full h-48 object-cover" src={project.img} alt={project.name} />
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2">{project.name}</div>
            <p className="text-white-700 text-base">
              {project.info}
            </p>
          </div>
          <div className="px-6 pt-4 pb-2">
            <button onClick={() => handleDelete(project.id)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
              Delete
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProjectsList;
