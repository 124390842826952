import './FormStyles.css'

import React, { useRef, useState } from 'react'
import { useForm } from "react-hook-form";
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import emailjs from "@emailjs/browser";
import 'react-toastify/dist/ReactToastify.css';

const Form = () => {



  const { register, handleSubmit,  formState: { errors, isSubmitting, isValidating } } = useForm({ mode: "onChange" });

  const sendEmail = async (formData) => {
    emailjs
      .send("service_mrn7kci", "template_46oijcg", formData, "QFk1KG8b1AHwvZt09")
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );


    toast.success('Votre message a bien éte envoyé');
  };


  return (
    <div className="form">

<ToastContainer
      position="top-right"
      outoClose={3000}

    />
      <form onSubmit={handleSubmit(sendEmail)}>
        <label>Nom</label>
        <input name='from_name'    {...register("from_name", { required: true, minLength: 3 })} type="text" />
        {errors.from_name && <p className="errors">Le nom doit comporter au moins 3 caractères</p>}

        <label>Email</label>
        <input name='email'    {...register("email", { required: true, pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g })} type="email" />
        {errors.email && <p className="errors">S'il vous plaît assurez-vous de fournir un email valide.</p>}

        <label>Votre message</label>
        <textarea name="message" rows="6"{...register("message", { required: true, minLength: 10 })} />
        {errors.message && <p className="errors">Votre message doit comporter au moins 10 caractères.</p>}
        <input type="submit" className="btn" disabled={isSubmitting} value="Envoyer" />

      </form>

    </div>
  )
}

export default Form