import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';
import Home from './routes/Home';
import About from './routes/About';
import Contact from './routes/Contact';
import Project from './routes/Project.js';
import ProjectDetails from './routes/ProjectDetails.js';
import ParticlesBackground from './components/ParticlesBackground';
import { AnimatePresence } from 'framer-motion';
import Test from './components/test';
import Form from './routes/Admin/Form';
import Admin from './routes/Admin';
import Works from './routes/Admin/Works';
import Login from './routes/Admin/Login';
import { PrivateRoute, PublicRoute, AuthProvider } from './routes/PrivateRoute';
import { ToastContainer, toast } from 'react-toastify';
function App() {
  return (
    <>
      {/* <ParticlesBackground /> */}
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/portfolio" element={<Project />} />
          <Route path="/portfolio/:id" element={<ProjectDetails />} />
          <Route path="/contact" element={<Contact />} />
          {/* Administration Routes */}
          <Route path="/admin" element={<PrivateRoute element={Admin} />}>
            <Route path="works" element={<Works />} />
            <Route path="add" element={<Form />} />
          </Route>
          <Route path="/login" element={<PublicRoute element={Login} />} />
        </Routes>
      </AuthProvider>
    </>
  );
}

export default App;
